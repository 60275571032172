import { Ico } from "@/assets/icons";
import {
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Button as ChakraButton,
} from "@chakra-ui/react";
import {
  BootcampBannerEntry,
  ValidateBootcampEnrrollments,
} from "@/schemaTypes";
import { useUser } from "@/providers/useUser";
import { ModalProps } from "./utils";
import { ModalHeader } from "./header";
import { ModalActions } from "./actions";
import { ModalBodyContent } from "./body";

export const BootcampCardModal = ({
  data,
  onSubmit,
  isOpen,
  onClose,
  refecthBootcamps,
}: ModalProps) => {
  const { user } = useUser();
  const handleClose = () => {
    if (
      data?.type === ValidateBootcampEnrrollments.Confirmblockenroll &&
      refecthBootcamps
    ) {
      refecthBootcamps();
    }
    onClose();
  };
  const handlePrimaryAction = () => {
    if (data && user) {
      onSubmit(
        data.bootcamp as BootcampBannerEntry,
        user.id,
        data.type as ValidateBootcampEnrrollments
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="md">
      <ModalOverlay />
      <ModalContent
        pt={6}
        pb={8}
        px={6}
        marginInline={{ base: "16px" }}
        mt={{ base: "138px", lg: "172px" }}
      >
        <Flex flexDirection="column" alignItems="end" gap={6}>
          <ChakraButton
            w="fit-content"
            fontSize="24px"
            variant="ghost"
            aria-label="Close"
            p={0}
            onClick={handleClose}
          >
            <Ico.Close />
          </ChakraButton>

          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={8}
          >
            <ModalHeader data={data} />

            <ModalBodyContent data={data} />

            <ModalActions
              data={data}
              onPrimaryAction={handlePrimaryAction}
              onSecondaryAction={handlePrimaryAction}
              refetchBootcamps={refecthBootcamps}
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
